import create, { GetState, SetState } from 'zustand';
import { combine } from 'zustand/middleware';
import { FormattedMessage } from 'react-intl';
import type { WWWRecentlyUpdatedCompanyDocument } from '../common/documents/WWWCompanyDocument';
import { nextApiRequest } from '../lib/utils';

export type RecentlyUpdatedState = {
  companies: WWWRecentlyUpdatedCompanyDocument[];

  isLoaded: boolean;
  isLoading: boolean;
};

export const recentlyUpdatedStateDefaults: RecentlyUpdatedState = {
  companies: [],

  isLoaded: false,
  isLoading: true,
};

export type StateAction<T = {}, D = {}> = (
  set: SetState<RecentlyUpdatedState>,
  get: GetState<RecentlyUpdatedState>
) => (data?: T) => Promise<{ status: string; error?: string | JSX.Element } & D>;

/** Load state from API the first time front-page is opened */
export const doLoadState: StateAction = (set, get) => async () => {
  const { isLoaded } = get();

  // Only do this once
  if (isLoaded) return { status: 'ok' };

  const { status, companies } = (await nextApiRequest(`/api/v1/www/company/recently-updated`)) as {
    status: string;
    companies: WWWRecentlyUpdatedCompanyDocument[];
  };

  if (status !== 'ok') {
    return {
      status,
      error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
    };
  }

  set({
    companies,

    isLoaded: true,
    isLoading: false,
  });

  return { status: 'ok' };
};

/** Store */
export const recentlyUpdatedState = create(
  combine(recentlyUpdatedStateDefaults, (set, get) => ({
    /** State methods */
    doLoadState: doLoadState(set, get),

    /** General set state */
    set,
  }))
);
