import React, { FC, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Header } from '../Header';
import { WWWRecentlyUpdatedCompanyDocument } from '../../common/documents/WWWCompanyDocument';
import { CompanyListRow } from '../company-list/CompanyListRow';
import { companyUrl } from '../../lib/utils';
import { Row } from '../company-list/CompanyListStyle';
import { CompanyListRowSkeleton } from '../company-list/CompanyListRowSkeleton';
import { cardStyles, borderRadius, mediaQuery } from '../Theme';

const Container = styled.div`
  ${mediaQuery.mobileFirst.medium(css``)}
`;

const itemSpacingRem = 0.4;

const List = styled.div`
  display: flex;
  flex-direction: column;
  ${cardStyles}
  padding: 0;

  ${mediaQuery.mobileFirst.medium(css`
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -${itemSpacingRem}rem;
    margin-left: -${itemSpacingRem}rem;
    width: calc(100% + ${itemSpacingRem * 2}rem);
    max-width: none;
    background: transparent;
    box-shadow: none;

    ${Row} {
      border-radius: ${borderRadius}px;
      border: none;
      ${cardStyles}
      margin: ${itemSpacingRem}rem;
      width: calc(50% - ${itemSpacingRem * 2}rem);

      > a {
        margin-top: 0;
        border: none;
      }
    }
  `)}

  ${mediaQuery.mobileFirst.large(css`
    ${Row} {
      margin: ${itemSpacingRem}rem;
      width: calc(33.33% - ${itemSpacingRem * 2}rem);
    }
  `)}
`;

export interface CompanyCarouselProps {
  title: string | JSX.Element;
  icon?: JSX.Element;
  companies: WWWRecentlyUpdatedCompanyDocument[];
  loading?: boolean;
}

export const CompanyCarousel: FC<CompanyCarouselProps> = ({ title, icon = null, companies = [], loading = false }) => {
  const loadingItems = useMemo(
    () => new Array(6).fill(null).map((_, index) => <CompanyListRowSkeleton key={index} sections={['name']} />),
    []
  );

  const renderCompany = useCallback(
    (company: WWWRecentlyUpdatedCompanyDocument) => {
      const url = companyUrl({ lang: 'fi', ...company });
      return <CompanyListRow key={company.companyID} company={{ ...company, url } as any} sections={['name']} />;
    },
    [loading]
  );

  return (
    <Container>
      <Header icon={icon}>{title}</Header>

      <List>
        {!loading && companies.map(renderCompany)}
        {loading && loadingItems}
      </List>
    </Container>
  );
};
