import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import FolderOpenIcon from 'mdi-react/FolderOpenIcon';
import { imageFromCDN } from '../../lib/utils';
import { Color, breakPoint } from '../Theme';

const Container = styled.div`
  padding: 0 2rem;
  margin-bottom: 10rem;
  box-sizing: border-box;

  p {
    font-size: 1.1rem;
    line-height: 1.5;
    opacity: 0.8;
  }
`;

const Img = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 35rem;
  height: 35rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  box-sizing: border-box;

  @media (max-width: ${breakPoint.medium}px) {
    margin: 4rem 6rem;
    height: 20rem;
    border-radius: 1rem;
  }

  @media (max-width: ${breakPoint.small}px) {
    margin: 0 0 2rem 0 !important;
    width: 100%;
  }
`;

const Info = styled.div`
  margin: 7rem auto 8rem auto;
  max-width: 750px;
  text-align: center;
  background: none;
  z-index: 1;

  h2 {
    margin-top: 2rem;
    margin-bottom: 1.2rem;
    font-weight: bold;

    @media (max-width: ${breakPoint.small}px) {
      font-size: 2.5rem;
    }
  }
`;

const Icon = styled.div`
  display: inline-flex;
  padding: 1rem;
  background-color: ${Color.primary};
  border-radius: 50%;
  color: ${Color.background};
`;

const Content = styled.div`
  min-height: 20rem;
  margin-top: 2rem;

  section {
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 4rem;
    width: 100%;
    max-width: 1920px;
    min-height: 20rem;

    box-sizing: border-box;

    &.left {
      > div {
        margin-right: 0rem;
      }

      ${Img} {
        filter: drop-shadow(-2.5rem 2.5rem 3rem rgba(0, 0, 0, 0.3));
      }

      ${Icon} {
        right: auto;
        left: 0;
      }
    }

    &.right {
      flex-direction: row-reverse;

      > div {
        margin-left: 0rem;
      }

      ${Img} {
        filter: drop-shadow(2.5rem 2.5rem 3rem rgba(0, 0, 0, 0.5));
      }

      h3,
      p {
        text-align: right;
      }
    }

    > div {
      max-width: 600px;

      h3 {
        position: relative;
        font-weight: bold;
        font-size: 2.5rem;
        margin-bottom: 1rem;

        ${Icon} {
          position: absolute;
          top: -5rem;
          right: 0;
        }
      }

      p {
        opacity: 0.6;
      }
    }

    @media (max-width: ${breakPoint.medium}px) {
      flex-direction: column !important;
    }

    @media (max-width: ${breakPoint.small}px) {
      padding: 0;
      margin-bottom: 5rem;

      > div:last-child {
        margin: 0;
      }

      h3,
      p {
        text-align: center !important;
      }
    }
  }
`;

export const CompanyShowcase: FC = ({ children }) => (
  <Container>
    <Content>
      <section className="left">
        <Img style={{ backgroundImage: `url(${imageFromCDN('/company_mockup.png')})` }} />

        <div>
          <Typography variant="h3">
            <Icon>
              <FolderOpenIcon size={28} />
            </Icon>
            <FormattedMessage id="frontPage.showcase.company.title" defaultMessage="Rikkaat yritysprofiilit" />
          </Typography>

          <Typography variant="body1">
            <FormattedMessage
              id="frontPage.showcase.company.text"
              defaultMessage="Constle huolehtii siitä että käytössäsi on aina ajan tasalla olevat ja automaattisesti päivittyvät yritystiedot. Voit myös itse täydentää omaa yritysprofiiliasi."
            />
          </Typography>
        </div>
      </section>
    </Content>
  </Container>
);
