import { WWWCompanyDocument } from '../common/documents/WWWCompanyDocument';

const COMPANY_HISTORY_KEY = 'companyHistory';

export interface CompanyHistoryItem
  extends Pick<WWWCompanyDocument, 'companyID' | 'flags' | 'name' | 'marketingName' | 'businessID' | 'logo'> {
  timeVisited: Date;
}

export const recordCompanyVisit = (company: WWWCompanyDocument) => {
  const { companyID, flags, name, marketingName, businessID, logo } = company;

  const companyHistoryItem: CompanyHistoryItem = {
    companyID,
    flags,
    name,
    marketingName,
    businessID,
    logo,
    timeVisited: new Date(),
  };

  const history = loadCompanyHistory();

  const existingItemIndex = history.findIndex((item) => item.companyID === companyID);
  if (existingItemIndex >= 0) {
    history[existingItemIndex] = companyHistoryItem;
  } else {
    history.push(companyHistoryItem);
  }

  history.sort((a, b) => b.timeVisited.valueOf() - a.timeVisited.valueOf());

  saveCompanyHistory(history.slice(0, 6));
};

const saveCompanyHistory = (history: CompanyHistoryItem[]) => {
  localStorage.setItem(COMPANY_HISTORY_KEY, JSON.stringify(history));
};

export const loadCompanyHistory = (): CompanyHistoryItem[] => {
  const history = JSON.parse(localStorage.getItem(COMPANY_HISTORY_KEY) || '[]');
  return history.map((item: CompanyHistoryItem) => ({ ...item, timeVisited: new Date(item.timeVisited) }));
};
