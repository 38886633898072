import { FC, useMemo } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { transparentize } from 'polished';
import Typography from '@material-ui/core/Typography';
import type { WWWListItem } from '../../common/documents/WWWList';
import { CompanyLogo } from '../company/CompanyLogo';
import { Section, Logo } from '../company-list/CompanyListStyle';
import { Flip } from '../Flip';
import { borderRadius, Color, compactCompanyRowHeight } from '../Theme';

export const ListItem = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  height: ${compactCompanyRowHeight}px;
  border-bottom: 2px solid ${Color.background};
  outline: none;
  z-index: 1;

  box-sizing: border-box;

  transition: box-shadow 100ms ease;

  &:first-of-type {
    border-radius: ${borderRadius}px ${borderRadius}px 0 0;

    &:after {
      top: 0;
      height: calc(100% + 2px);
      border-radius: ${borderRadius}px ${borderRadius}px 0 0;
    }
  }

  &:last-of-type {
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
    border-bottom: none;

    &:after {
      height: calc(100% + 2px);
      border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
    }
  }

  &:first-of-type:last-of-type {
    border-radius: ${borderRadius}px;
  }

  &[aria-selected='true'] {
    background-color: ${Color.select};

    &:after {
      display: block;
      border: 2px solid ${Color.primary};
    }
  }

  &:after {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    height: calc(100% + 4px);
    border: 2px solid transparent;
    border-radius: inherit;
    pointer-events: none;

    box-sizing: border-box;
  }

  &:hover {
    background-color: ${Color.hover};
    box-shadow: 0 0 11px rgba(149, 157, 165, 0.15);
    z-index: 2;

    &:after {
      display: block;
      border: 2px solid ${Color.secondary};
    }

    > button {
      opacity: 1;
    }
  }

  &:active,
  &:focus {
    background-color: ${Color.select};

    &:after {
      display: block;
      border: 2px solid ${Color.primary};
    }

    > button {
      opacity: 1;
    }
  }

  small {
    display: block;
    margin-top: 0.2rem;
    font-size: 0.8rem;
    color: ${Color.disabled};
  }

  p {
    display: flex;
    margin-bottom: 2px;

    > svg {
      // margin-bottom: -2px;
      color: ${transparentize(0.5, Color.black)};
    }
  }

  ${Logo} {
    margin: 0 1.5rem;
  }

  ${Section}:first-of-type {
    flex: 1;
  }

  ${Section}:last-of-type {
    flex-shrink: 0;
  }
`;

export const ListsShowcaseCompany: FC<Omit<WWWListItem, 'name'> & { name: string | JSX.Element }> = (listItem) => {
  const { businessID, name, logo, url } = listItem;

  const companyLogo = useMemo(() => <CompanyLogo absolutePath logo={logo} />, [logo?.path]);

  return (
    <Link href={url} passHref>
      <ListItem tabIndex={-1}>
        <Logo>
          <Flip>{companyLogo}</Flip>
        </Logo>

        <Section flex width={null} className="name">
          <Typography variant="body1">
            <Flip>{name}</Flip>
          </Typography>
          <Typography variant="body2">
            <Flip>{businessID}</Flip>
          </Typography>
        </Section>
      </ListItem>
    </Link>
  );
};
