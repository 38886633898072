import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import { useOnScreen } from '../../hooks/useOnScreen';
import { Color, borderRadius } from '../Theme';
import { lists } from './exampleListData';
import { ListsShowcaseCompany } from './ListsShowcaseCompany';

const Container = styled.aside`
  width: min(calc(100vw - 16px), 400px);
  height: 325px;
  background-color: ${Color.white};
  border-radius: ${borderRadius}px;
  overflow: hidden;

  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
`;

const changeItemName = (id: number, name: string) => (items: (typeof lists)[number]['items']) => {
  const index = items.findIndex((item) => item.listItemID === id);
  items[index].name = name;
  return items;
};

const changeItemLogo = (id: number, logo: string | null) => (items: (typeof lists)[number]['items']) => {
  const index = items.findIndex((item) => item.listItemID === id);
  items[index].logo = logo ? { path: logo } : null;
  return items;
};

export const ListsShowcaseList: FC = () => {
  const isSmall = useBreakPoint('small');
  const containerRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(containerRef, { rootMargin: `${isSmall ? '-30%' : '-350px'}` });
  const [items, setItems] = useState(lists[4].items);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!isVisible) return;

    const animate = () =>
      setStep((step) => {
        if (step === 1) setItems(changeItemLogo(26381, '/demoCompany2.png'));
        if (step === 2) setItems(changeItemName(26384, 'Yritys 1 Oy Ab'));
        if (step === 4) setItems(changeItemLogo(26376, '/demoCompany3.png'));
        if (step === 5) setItems(changeItemName(26378, 'Yritys 3 Ltd'));
        if (step === 6) setItems(changeItemName(26384, 'Yritys 1 Oy'));
        if (step === 7) setItems(changeItemLogo(26376, null));
        if (step === 9) setItems(changeItemLogo(26381, null));
        if (step === 10) setItems(changeItemName(26378, 'Yritys 3'));
        return (step + 1) % 12;
      });

    const timeout = setTimeout(animate, 500);
    const interval = setInterval(animate, 1000);
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [isVisible]);

  return (
    <Container ref={containerRef}>
      {items.map((item) => (
        <ListsShowcaseCompany key={item.listItemID} {...item} />
      ))}
    </Container>
  );
};
