import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ThumbUpIcon from 'mdi-react/ThumbUpIcon';
import AlertDecagramIcon from 'mdi-react/AlertDecagramIcon';
import ClipboardTextIcon from 'mdi-react/ClipboardTextIcon';
import { ListType } from '../../common/Constants';
import type { WWWListContactPerson } from '../../common/documents/WWWList';
import { useUser } from '../../context/useUser';
import { CompanyLogo } from '../company/CompanyLogo';
import { Color } from '../Theme';

const DualIcon = styled.div`
  position: relative;
  width: 24px;
  height: 24px;

  figure {
    position: absolute;
    bottom: -7px;
    right: -7px;
    border: 2px solid ${Color.white} !important;
  }
`;

const CompanyListIcon = memo(() => {
  const { companies } = useUser();

  const logo = companies[0]?.logo;

  return (
    <DualIcon>
      <ClipboardTextIcon />
      <CompanyLogo size={20} logo={logo} />
    </DualIcon>
  );
});

export const listTypeToIcon: Record<ListType, JSX.Element> = {
  [ListType.LIKED]: <ThumbUpIcon color={Color.green} />,
  [ListType.DISLIKED]: <AlertDecagramIcon color={Color.red} />,
  [ListType.USERLIST]: <ClipboardTextIcon />,
  [ListType.COMPANYLIST]: <CompanyListIcon />,
} as const;

export const listTypeToDefaultTitle: Record<ListType, JSX.Element | null> = {
  [ListType.LIKED]: <FormattedMessage id="lists.likedTitle" defaultMessage="Tykätyt" />,
  [ListType.DISLIKED]: <FormattedMessage id="lists.dislikedTitle" defaultMessage="Huomioi" />,
  [ListType.USERLIST]: null,
  [ListType.COMPANYLIST]: null,
};

export const getListContactPersonDetails = ({ listContactPersonID: _, contactPerson, role, ...other }: WWWListContactPerson) => {
  if (contactPerson) {
    const { firstname, lastname, title, email, phone } = contactPerson;

    return {
      name: `${firstname} ${lastname}`.trim(),
      title,
      email,
      phone,
      role,
    };
  }

  return {
    ...other,
    role,
  };
};
