import { FC, isValidElement, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import MenuList from '@material-ui/core/MenuList';
import StoreIcon from 'mdi-react/StoreIcon';
import ClipboardTextMultipleIcon from 'mdi-react/ClipboardTextMultipleIcon';
import type { WWWList, WWWListGroup } from '../../common/documents/WWWList';
import { ListType } from '../../common/Constants';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import { useOnScreen } from '../../hooks/useOnScreen';
import { MenuItem, MenuItemProps } from '../Menu';
import { Color, borderRadius, breakPoint } from '../Theme';
import { listGroups, lists } from './exampleListData';

const Container = styled.aside`
  width: min(calc(100vw - 16px), 400px);
  height: 325px;
  pointer-events: none;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Color.white};
  border-radius: ${borderRadius}px;
  overflow: hidden;

  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
`;

const Button = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -58px;
  right: 32px;
  width: 42px;
  height: 42px;
  background-color: ${transparentize(0.2, Color.white)};
  border-radius: 50%;

  > svg {
    color: #696969;
  }

  &::after {
    position: absolute;
    content: '';
    bottom: -16px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    border-bottom: 8px solid ${Color.white};
  }

  @media (max-width: ${breakPoint.small}px) {
    display: none;
  }
`;

const ListTitle = styled.span<{ shared: boolean }>`
  position: relative;
  padding-left: ${({ shared }) => (shared ? 18 + 6 : 0)}px;

  .mdi-icon {
    position: absolute;
    left: 0;
    margin-top: 3px;
    color: ${Color.secondary};
    opacity: 0.6;
  }
`;

export const ListsShowcaseMenu: FC = () => {
  const isSmall = useBreakPoint('small');
  const containerRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(containerRef, { rootMargin: `${isSmall ? '-30%' : '-350px'}` });
  const [openListGroupID, setOpenListGroupID] = useState(0);
  const [selectedListIDs, setSelectedListIDs] = useState([]);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!isVisible) return;

    const animate = () =>
      setStep((step) => {
        if (step === 0) setOpenListGroupID(1025);
        if (step === 1) setSelectedListIDs([6508]);
        if (step === 2) setSelectedListIDs([6508, 6506]);
        if (step === 3) setSelectedListIDs([6508, 6506, 6507]);
        if (step === 6) setOpenListGroupID(0);
        if (step === 7) setSelectedListIDs([]);
        return (step + 1) % 10;
      });

    const timeout = setTimeout(animate, 500);
    const interval = setInterval(animate, 1000);
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [isVisible]);

  const listToMenuItem = useCallback(
    (list: WWWList) => {
      const isShared = list.listTypeID === ListType.COMPANYLIST;
      return {
        id: `${list.listID}`,
        title: (
          <ListTitle shared={isShared}>
            {isShared && <StoreIcon size={18} />}
            {list.label}
          </ListTitle>
        ),
        selected: selectedListIDs.includes(list.listID),
        checkbox: true,
        onClick: () => {},
      };
    },
    [selectedListIDs]
  );

  const listGroupToMenuItem = useCallback(
    ({ listGroupID, label }: WWWListGroup, index) => {
      return {
        id: `listGroup-${listGroupID}`,
        title: <ListTitle shared={false}>{label}</ListTitle>,
        selected: false,
        checkbox: false,
        overrideOpen: openListGroupID === listGroupID,
        items: (lists.filter((list) => list.listGroupID === listGroupID) || []).map(listToMenuItem),
        onClick: () => {},
      };
    },
    [lists, listToMenuItem, openListGroupID]
  );

  const renderMenuItem = useCallback((item: MenuItemProps | JSX.Element | null) => {
    if (item === null) return null;
    if (isValidElement(item)) return item;

    const { id, title, elementProps = {}, header } = item as MenuItemProps;

    const isCheckbox = true;

    if (header) {
      if (!title) return <Divider key={id} />;
      return (
        <ListSubheader key={id} {...elementProps}>
          {title}
        </ListSubheader>
      );
    }

    return <MenuItem key={id} {...(item as MenuItemProps)} checkbox={isCheckbox} />;
  }, []);

  return (
    <Container>
      <Button>
        <ClipboardTextMultipleIcon size={24} />
      </Button>
      <Content ref={containerRef}>
        <MenuList tabIndex={-1}>
          {[
            <ListSubheader key="lists-subheader" tabIndex={-1}>
              Listat
            </ListSubheader>,
            ...listGroups.map(listGroupToMenuItem),
          ].map(renderMenuItem)}
        </MenuList>
      </Content>
    </Container>
  );
};
