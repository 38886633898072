import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import type { WWWList } from '../../common/documents/WWWList';
import { ListType } from '../../common/Constants';
import { Badge } from '../Badge';
import { listTypeToDefaultTitle, listTypeToIcon } from './listsUtils';

const sharedListSubtitle = <FormattedMessage id="lists.sharedTitle" defaultMessage="Jaettu lista" />;

export const ListsListRow: FC<WWWList & { tabIndex?: number; inner?: boolean; selected: boolean; onSelect: (id: number) => void }> = ({
  listID,
  listTypeID,
  label,
  items,
  tabIndex,
  inner = false,
  selected,
  onSelect,
}) => {
  const handleClick = useCallback(() => onSelect(listID), [listID, onSelect]);

  const isShared = listTypeID === ListType.COMPANYLIST;

  return (
    <ListItem tabIndex={tabIndex} selected={selected} button onClick={handleClick} style={inner ? { paddingLeft: '2rem' } : {}}>
      <ListItemIcon>{listTypeToIcon[listTypeID]}</ListItemIcon>
      <ListItemText
        primary={label || listTypeToDefaultTitle[listTypeID]}
        secondary={isShared && sharedListSubtitle}
        style={{ wordBreak: 'break-word' }}
      />
      <ListItemSecondaryAction className="ignore">
        <Badge plain>{items?.length || 0}</Badge>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
