export const listGroups = [
  {
    listGroupID: 1024,
    label: '1. Maa ja pohja',
  },
  {
    listGroupID: 1025,
    label: '2. Perustukset ja ulkopuoliset',
  },
  {
    listGroupID: 1026,
    label: '3. Runko ja vesikatto',
  },
  {
    listGroupID: 1027,
    label: '4. Täydentävät',
  },
  {
    listGroupID: 1028,
    label: '5. Pintarakenteet',
  },
  {
    listGroupID: 1029,
    label: '6. Kalusteet ja varusteet',
  },
  {
    listGroupID: 1030,
    label: '7. Konetekniset',
  },
];

const demoListItem = {
  listItemID: 1,
  companyID: 1,
  name: 'Yritys',
  businessID: '1234567-0',
  email: '',
  isRegistered: false,
  isInvited: false,
  logo: null,
  domicile: {
    cityID: 13096,
    cityName: 'Kerava',
    provinceID: 12001,
    provinceName: 'Uusimaa',
    countryID: 11001,
    countryName: 'suomi',
  },
  companyContactPersons: [],
  listContactPersons: [],
  pendingContactPersonInvites: [],
  operationalAreaIDs: [],
  comments: [],
  url: '',
  yearlyBuys: null,
};

export const lists = [
  // Dummy lists
  {
    listID: 1,
    listTypeID: 100,
    label: 'List',
    listGroupID: 1024,
    items: [],
  },
  {
    listID: 2,
    listTypeID: 100,
    label: 'List',
    listGroupID: 1027,
    items: [],
  },
  {
    listID: 3,
    listTypeID: 100,
    label: 'List',
    listGroupID: 1029,
    items: [],
  },
  {
    listID: 4,
    listTypeID: 100,
    label: 'List',
    listGroupID: 1030,
    items: [],
  },

  // Preview list
  {
    listID: 6537,
    listTypeID: 100,
    label: 'ALAKATTOTYÖT',
    listGroupID: 1028,
    items: [
      {
        listItemID: 26384,
        companyID: 418801,
        name: 'Yritys 1 Oy',
        businessID: '1234567-0',
        email: '',
        isRegistered: false,
        isInvited: false,
        logo: null,
        domicile: {
          cityID: 13275,
          cityName: 'Tampere',
          provinceID: 12006,
          provinceName: 'Pirkanmaa',
          countryID: 11001,
          countryName: 'suomi',
        },
        companyContactPersons: [],
        listContactPersons: [],
        pendingContactPersonInvites: [],
        operationalAreaIDs: [],
        comments: [],
        url: '',
        yearlyBuys: null,
      },
      {
        listItemID: 26381,
        companyID: 419280,
        name: 'Yritys Kaksi Oy',
        businessID: '1234567-0',
        email: '',
        isRegistered: false,
        isInvited: false,
        logo: null,
        domicile: {
          cityID: 13018,
          cityName: 'Forssa',
          provinceID: 12005,
          provinceName: 'Kanta-Häme',
          countryID: 11001,
          countryName: 'suomi',
        },
        companyContactPersons: [],
        listContactPersons: [],
        pendingContactPersonInvites: [],
        operationalAreaIDs: [],
        comments: [],
        url: '',
        yearlyBuys: null,
      },
      {
        listItemID: 26378,
        companyID: 425498,
        name: 'Yritys 3',
        businessID: '1234567-0',
        email: '',
        isRegistered: false,
        isInvited: false,
        logo: {
          path: '/demoCompany1.png',
        },
        domicile: {
          cityID: 13030,
          cityName: 'Helsinki',
          provinceID: 12001,
          provinceName: 'Uusimaa',
          countryID: 11001,
          countryName: 'suomi',
        },
        companyContactPersons: [],
        listContactPersons: [],
        pendingContactPersonInvites: [],
        operationalAreaIDs: [],
        comments: [],
        url: '',
        yearlyBuys: null,
      },
      {
        listItemID: 26376,
        companyID: 434302,
        name: 'Yritys Neljä Ltd Oy',
        businessID: '1234567-0',
        email: '',
        isRegistered: false,
        isInvited: false,
        logo: null,
        domicile: {
          cityID: 13080,
          cityName: 'Karkkila',
          provinceID: 12001,
          provinceName: 'Uusimaa',
          countryID: 11001,
          countryName: 'suomi',
        },
        companyContactPersons: [],
        listContactPersons: [],
        pendingContactPersonInvites: [],
        operationalAreaIDs: [],
        comments: [],
        url: '',
        yearlyBuys: null,
      },
      {
        listItemID: 26380,
        companyID: 443060,
        name: 'Yritys 5 Oy Ab',
        businessID: '1234567-0',
        email: '',
        isRegistered: false,
        isInvited: false,
        logo: null,
        domicile: {
          cityID: 13140,
          cityName: 'Lahti',
          provinceID: 12007,
          provinceName: 'Päijät-Häme',
          countryID: 11001,
          countryName: 'suomi',
        },
        companyContactPersons: [],
        listContactPersons: [],
        pendingContactPersonInvites: [],
        operationalAreaIDs: [],
        comments: [],
        url: '',
        yearlyBuys: null,
      },
      {
        listItemID: 26370,
        companyID: 452978,
        name: 'Yritys 6',
        businessID: '1234567-0',
        email: '',
        isRegistered: false,
        isInvited: false,
        logo: null,
        domicile: {
          cityID: 13030,
          cityName: 'Helsinki',
          provinceID: 12001,
          provinceName: 'Uusimaa',
          countryID: 11001,
          countryName: 'suomi',
        },
        companyContactPersons: [],
        listContactPersons: [],
        pendingContactPersonInvites: [],
        operationalAreaIDs: [],
        comments: [],
        url: '',
        yearlyBuys: null,
      },
    ],
  },

  // Preview list
  {
    listID: 3795,
    listTypeID: 4,
    label: 'LISTA',
    listGroupID: null,
    items: [
      { ...demoListItem, listItemID: 1 },
      { ...demoListItem, listItemID: 2 },
      { ...demoListItem, listItemID: 3 },
      { ...demoListItem, listItemID: 4 },
    ],
  },
  {
    listID: 6510,
    listTypeID: 100,
    label: 'BETONIELEMENTIT',
    listGroupID: 1026,
    items: [
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
    ],
  },
  {
    listID: 6517,
    listTypeID: 100,
    label: 'BETONISET PORRASELEMENTIT',
    listGroupID: 1026,
    items: [demoListItem, demoListItem, demoListItem, demoListItem, demoListItem, demoListItem],
  },
  {
    listID: 3939,
    listTypeID: 4,
    label: 'LIST',
    listGroupID: null,
    items: [demoListItem, demoListItem, demoListItem, demoListItem],
  },
  {
    listID: 3796,
    listTypeID: 4,
    label: 'LIST',
    listGroupID: null,
    items: [],
  },
  {
    listID: 6511,
    listTypeID: 100,
    label: 'ELEMENTTIASENNUS',
    listGroupID: 1026,
    items: [
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
    ],
  },
  {
    listID: 6508,
    listTypeID: 100,
    label: 'LATTIAVALUT',
    listGroupID: 1025,
    items: [
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
    ],
  },
  {
    listID: 6506,
    listTypeID: 100,
    label: 'PERUSTALAUDOITUKSET YM',
    listGroupID: 1025,
    items: [
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
    ],
  },
  {
    listID: 6507,
    listTypeID: 100,
    label: 'RAUDOITUS',
    listGroupID: 1025,
    items: [
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
      demoListItem,
    ],
  },
];
