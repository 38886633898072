import { FC, HTMLProps, ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const ANIMATION_DURATION_IN_MS = 1000;

const flipStyle = css`
  display: block;
  position: relative;

  transition: height ${ANIMATION_DURATION_IN_MS}ms cubic-bezier(0.25, 1, 0.5, 1);
`;

const flipContainerStyle = css`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > * {
    position: absolute;
    top: 0;
    margin: 0;
  }

  > :last-of-type:not(:first-of-type) {
    opacity: 0;
  }
`;

const flipCurrentAnimation = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const flipPreviousAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(100%);
  }
`;

const flipAnimateStyle = css`
  > span:first-of-type {
    opacity: 0;
  }

  > .container {
    > .current {
      animation: ${flipCurrentAnimation} ${ANIMATION_DURATION_IN_MS}ms cubic-bezier(0.25, 1, 0.5, 1);
    }

    > .previous {
      animation: ${flipPreviousAnimation} ${ANIMATION_DURATION_IN_MS}ms cubic-bezier(0.25, 1, 0.5, 1);
    }
  }
`;

const Container = styled.span<{ $animate: boolean }>`
  ${flipStyle}
  ${(props) => (props.$animate ? flipAnimateStyle : null)};
`;

const Animated = styled.span`
  ${flipContainerStyle}
`;

export const Flip: FC<HTMLProps<HTMLSpanElement>> = ({ children, ...props }) => {
  const currentRef = useRef<HTMLParagraphElement>(null);
  const [current, setCurrent] = useState<ReactNode>(children);
  const [previous, setPrevious] = useState<ReactNode>(null);
  const [height, setHeight] = useState<number | null>(null);
  const [isHeightUpdated, setIsHeightUpdated] = useState(true);

  const isPreviousSet = previous !== null && previous !== current;

  useEffect(() => {
    setCurrent((previous) => {
      setPrevious(previous);
      setIsHeightUpdated(true);
      return children;
    });

    const animationEndTimeout = setTimeout(() => {
      setPrevious(null);
    }, ANIMATION_DURATION_IN_MS);

    return () => clearTimeout(animationEndTimeout);
  }, [children]);

  useEffect(() => {
    if (!currentRef.current) return;
    if (isHeightUpdated === true) {
      setHeight(currentRef.current.getBoundingClientRect().height);
      setIsHeightUpdated(false);
    }
  }, [isHeightUpdated]);

  return (
    <Container $animate={isPreviousSet} style={height !== null ? { height } : {}}>
      <span ref={currentRef as any} {...props}>
        {current}
      </span>
      {isPreviousSet && (
        <Animated className="container" aria-hidden>
          <span {...(props as any)} className="current">
            {current}
          </span>
          <span {...(props as any)} className="previous">
            {previous}
          </span>
        </Animated>
      )}
    </Container>
  );
};
