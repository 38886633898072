import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import ClipboardTextMultipleIcon from 'mdi-react/ClipboardTextMultipleIcon';
import { Badge } from '../Badge';
import { breakPoint, Color, layoutStyles } from '../Theme';
import { ListsShowcaseSidebar } from './ListsShowcaseSidebar';
import { ListsShowcaseMenu } from './ListsShowcaseMenu';
import { ListsShowcaseList } from './ListsShowcaseList';

const Content = styled.article`
  ${layoutStyles}
  min-height: 20rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  > div {
    > h3 {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 2.5rem;
      font-weight: bold;

      > .badge {
        margin-left: 16px;
        margin-bottom: -4px;
      }
    }

    > h5 {
      padding-left: calc(64px + 13px);
      opacity: 0.7;
    }

    > p {
      margin-top: 12px;
      padding-left: calc(64px + 13px);
      max-width: 65ch;
      font-size: 1.1rem;
      opacity: 0.6;
    }
  }
`;

const Icon = styled.div`
  display: inline-flex;
  margin-right: 16px;
  padding: 1rem;
  background-color: ${Color.primary};
  border-radius: 50%;
  color: ${Color.background};
`;

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-of-type(1) {
    transform: translateX(20%);

    > div {
      // transform: rotate3d(0.5, 1, 0, 8deg);
      filter: drop-shadow(1.5rem -1.5rem 3rem rgba(0, 0, 0, 0.2));
    }
  }

  &:nth-of-type(2) {
    transform: translateX(-20%);

    > h5 {
      margin-left: 64px;

      &::before {
        content: '2';
        right: 0;
      }
    }

    > div {
      // transform: rotate3d(-0.5, -1, 0, 8deg);
      filter: drop-shadow(-1.5rem -1.5rem 3rem rgba(0, 0, 0, 0.2));
    }
  }

  &:nth-of-type(3) {
    transform: translateX(10%);

    > h5 {
      &::before {
        content: '3';
      }
    }

    > div {
      // transform: rotate3d(1, -1, 0, 8deg);
      filter: drop-shadow(1.5rem 1.5rem 3rem rgba(0, 0, 0, 0.2));
    }
  }

  &:not(:last-of-type) {
    margin: 2rem 0;
  }

  > h5 {
    position: relative;
    width: min(100%, 400px);

    &::before {
      content: '1';
      position: absolute;
      margin: -32px 0 0 -32px;
      width: 32px;
      height: 32px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      line-height: 32px;
      background-color: ${Color.primary};
      border-radius: 50%;
      color: ${Color.white};
    }
  }

  > div {
    transform: translateZ(0);
  }

  @media (max-width: ${breakPoint.medium}px) {
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3) {
      transform: none;
    }
  }

  @media (max-width: ${breakPoint.small}px) {
    margin-top: 72px;
    flex-direction: column;

    &:nth-of-type(2) {
      flex-direction: column-reverse;

      > h5 {
        margin-left: 0;

        &::before {
          right: auto;
        }
      }
    }

    > h5 {
      margin-bottom: 32px;

      &::before {
        margin: -42px 0 0 0;
      }
    }
  }
`;

export const ListsShowcase: FC = () => {
  return (
    <Content>
      <div>
        <Typography variant="h3">
          <Icon>
            <ClipboardTextMultipleIcon size={28} />
          </Icon>
          <FormattedMessage id="frontPage.showcase.lists.title" defaultMessage="Listat" />
          <Badge plain>Ilmainen</Badge>
        </Typography>
        <Typography variant="h5">
          <FormattedMessage id="frontPage.showcase.lists.subtitle" defaultMessage="Automaattisesti päivittyvä toimittajarekisteri" />
        </Typography>
        <Typography variant="body1">
          Esittelyssä Constlen Listat-ominaisuus. Listat on helppokäyttöinen ja aina ajan tasalla oleva toimittajarekisteri, räätälöytynä
          juuri sinun yrityksesi tarpeisiin. Listat-ominaisuus on saatavilla ilmaiseksi kaikille rekisteröityneille käyttäjille.
        </Typography>

        <Section>
          <Typography variant="h5">
            <FormattedMessage
              id="frontPage.showcase.lists.organization"
              defaultMessage="Organisoi toimittajarekisterisi helposti kansiorakenteen avulla."
            />
          </Typography>

          <div aria-hidden>
            <ListsShowcaseSidebar />
          </div>
        </Section>

        <Section>
          <div aria-hidden>
            <ListsShowcaseMenu />
          </div>

          <Typography variant="h5">
            <FormattedMessage
              id="frontPage.showcase.lists.addition"
              defaultMessage="Lisää yrityksiä rekisteriisi suoraan hakutuloksista tai yritysprofiilista."
            />
          </Typography>
        </Section>

        <Section>
          <Typography variant="h5">
            <FormattedMessage
              id="frontPage.showcase.lists.updates"
              defaultMessage="Yritysrekisterisi päivittyy automaattisesti. Uudet tiedot haetaan päivittäin."
            />
          </Typography>

          <div aria-hidden>
            <ListsShowcaseList />
          </div>
        </Section>
      </div>
    </Content>
  );
};
