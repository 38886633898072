import React, { FC, useEffect, useState } from 'react';
import { GetStaticProps } from 'next';
import styled from 'styled-components';
import { darken, lighten, transparentize } from 'polished';
import { FormattedMessage } from 'react-intl';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import ShieldCheckIcon from 'mdi-react/ShieldCheckIcon';
import BriefcaseSearchIcon from 'mdi-react/BriefcaseSearchIcon';
import DiamondIcon from 'mdi-react/DiamondIcon';
import HistoryIcon from 'mdi-react/HistoryIcon';
import NewspaperVariantMultipleIcon from 'mdi-react/NewspaperVariantMultipleIcon';
import { Header } from '../components/Header';
import { Omnibar } from '../components/omnibar/Omnibar';
import { Button } from '../components/Button';
import { fetchAdvancedSearchOptions } from '../context/advancedSearchStore';
import { SearchHero } from '../components/SearchHero';
import { FrontPage as SEO } from '../components/SEO';
import { useUser } from '../context/useUser';
import { imageFromCDN } from '../lib/utils';
import { Flip } from '../components/Flip';

import { gaEvent } from '../lib/ga';
import { CompanyCarousel } from '../components/search/CompanyCarousel';
import { useScopedState } from '../lib/state';
import { recentlyUpdatedState } from '../context/recentlyUpdatedStore';
import { Color, cardStyles, layoutStyles, breakPoint, navbarHeight, borderRadius } from '../components/Theme';
import { loadCompanyHistory } from '../lib/companyHistory';
import { ListsShowcase } from '../components/frontpage/ListsShowcase';
import { CompanyShowcase } from '../components/frontpage/CompanyShowcase';

const Page = styled.div`
  background-image: url('/constle-stripes-long.svg');
  background-position: center 55%;
  background-repeat: no-repeat;
  background-size: 100% 4000px;

  @media (max-width: 900px) {
    background-image: none;
  }
`;

const Styled = {
  Hero: styled.div`
    padding: calc(3rem + ${navbarHeight}px) 0 9rem 0;
    background-color: ${Color.secondary};
    // https://unsplash.com/photos/VLPUm5wP5Z0
    background-image: linear-gradient(198deg, ${transparentize(0.8, Color.secondary)}, ${transparentize(0.3, Color.secondary)}),
      url(${imageFromCDN('/constle_hero_background.jpg')});
    background-position: center 20%;
    background-size: cover;
    box-shadow: none;

    @media (max-width: 750px) {
      padding: 6rem 0 8rem 0;
      text-align: center;

      background-image: linear-gradient(to bottom, ${transparentize(0.4, Color.secondary)}, ${transparentize(0.6, Color.secondary)}),
        url(${imageFromCDN('/constle_hero_background.jpg')});
    }

    > div {
      ${layoutStyles}
      position: relative;
    }

    img {
      max-width: 100%;
    }

    h1 {
      margin: 0 0 0.5rem 0;
      font-size: 2.5rem;
      max-width: 30ch;
      color: ${Color.white};

      > span {
        color: ${Color.primary};
      }

      @media (max-width: ${breakPoint.small}px) {
        font-size: 2rem;
      }
    }

    h2 {
      margin: 0 0 0.3rem 0;
      font-size: 1.4rem;
      font-weight: 500;
      color: ${lighten(0.2, Color.primary)};

      @media (max-width: 750px) {
        font-size: 1.2rem;
      }
    }

    p,
    small {
      margin: 0;
      margin-top: 1rem;
      max-width: 50%;
      font-size: 1.1rem;
      line-height: 1.6rem;
      color: ${Color.white};

      @media (max-width: ${breakPoint.medium}px) {
        max-width: 70%;
      }

      @media (max-width: ${breakPoint.small}px) {
        padding: 0 2rem;
        max-width: 100%;
        font-size: 1rem;
        box-sizing: border-box;
      }
    }

    small {
      font-size: 0.7rem;
      opacity: 0.3;
    }

    .MuiButtonBase-root {
      margin: 24px 0 0 0 !important;

      /* position: absolute;
      margin-left: 0 !important;
      left: calc(50% + 3rem);
      top: 50%;
      transform: translate(0, -50%);

      @media (max-width: ${breakPoint.medium}px) {
        left: auto;
        right: 1rem;
      }

      @media (max-width: ${breakPoint.small}px) {
        position: relative;
        margin-top: 2rem;
        transform: none;
      } */
    }
  `,
  VgBanners: styled.div`
    ${cardStyles}
    overflow: hidden;

    a {
      display: flex;
    }

    img {
      max-width: 100%;
    }
  `,
  Scaffold: styled.div`
    ${layoutStyles}
  `,
  Search: styled.div`
    margin-top: -6.7rem;

    > h2 {
      margin-bottom: 2.8rem;
      font-weight: 500;
      color: ${lighten(0.2, Color.primary)};
    }
  `,
  Content: styled.div`
    ${cardStyles}
  `,
  About: styled.div`
    position: relative;
    padding: 5rem 4rem;
    ${cardStyles}
    overflow: hidden;

    @media (max-width: ${breakPoint.small}px) {
      padding: 3rem;
    }

    h1 {
      margin: 0 0 2rem 0;
      color: ${Color.secondary};

      svg {
        margin-right: 0.3rem;
        opacity: 0.5;
      }

      @media (max-width: ${breakPoint.small}px) {
        font-size: 1.5rem;

        svg {
          margin-right: 0.2rem;
          margin-bottom: -3px;
        }
      }
    }

    p {
      font-size: 1.1rem;
      line-height: 150%;
      color: ${darken(0.15, Color.secondary)};

      @media (max-width: ${breakPoint.small}px) {
        font-size: 1rem;
      }
    }

    > div:first-of-type {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      > div:first-of-type {
        flex: 1;

        @media (max-width: ${breakPoint.small}px) {
          display: none;
        }
      }

      > div:last-of-type {
        flex: 1;

        // https://unsplash.com/photos/sb7RUrRMaC4
        background-image: linear-gradient(to right, ${Color.white}, ${transparentize(0.8, Color.white)}),
          url(${imageFromCDN('/constle_about_background.png')});
        background-position: center;
        background-size: cover;
        background-color: ${Color.secondary};

        @media (max-width: ${breakPoint.small}px) {
          background-image: linear-gradient(to right, ${Color.white}, ${transparentize(0.3, Color.white)}),
            url(${imageFromCDN('/constle_about_background.png')});
        }
      }
    }

    > div:last-of-type {
      position: relative;
      max-width: 55%;

      @media (max-width: ${breakPoint.small}px) {
        max-width: 100%;
      }
    }
  `,
  Callout: styled.div`
    position: relative;
    padding: 5rem 4rem;
    ${cardStyles}
    background-color: ${Color.secondary};
    overflow: hidden;

    @media (max-width: ${breakPoint.small}px) {
      padding: 2rem;
    }

    h1 {
      margin: 0 0 2rem 0;
      color: ${lighten(0.2, Color.primary)};

      svg {
        margin-right: 0.3rem;
        opacity: 0.5;
      }

      @media (max-width: ${breakPoint.small}px) {
        font-size: 1.5rem;

        svg {
          margin-right: 0.2rem;
          margin-bottom: -3px;
        }
      }
    }

    p {
      font-size: 1.1rem;
      line-height: 150%;
      color: ${Color.white};

      b {
        display: block;
        margin-bottom: 1rem;
      }

      @media (max-width: ${breakPoint.small}px) {
        font-size: 1rem;
      }
    }

    .MuiButtonBase-root {
      margin-left: 0 !important;
      margin-top: 1.5rem;
    }

    > div:first-of-type {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      > div:first-of-type {
        flex: 1;
        background-color: ${Color.secondary};

        @media (max-width: ${breakPoint.small}px) {
          display: none;
        }
      }

      > div:last-of-type {
        flex: 1;

        // https://unsplash.com/photos/YDVdprpgHv4
        background-image: linear-gradient(to right, ${Color.secondary}, ${transparentize(0.8, Color.secondary)}),
          url(${imageFromCDN('/constle_callout_background.png')});
        background-position: center;
        background-size: cover;
        background-color: ${Color.secondary};

        @media (max-width: ${breakPoint.small}px) {
          background-image: linear-gradient(to right, ${Color.secondary}, ${transparentize(0.3, Color.secondary)}),
            url(${imageFromCDN('/constle_callout_background.png')});
        }
      }
    }

    > div:last-of-type {
      position: relative;
      max-width: 55%;

      @media (max-width: ${breakPoint.small}px) {
        max-width: 100%;
      }
    }
  `,
  Info: styled.div`
    display: flex;
    ${layoutStyles}
    margin-bottom: 4rem;
    min-height: 260px;
    border: 2px dashed ${Color.disabled};
    border-radius: ${borderRadius}px;

    @media (max-width: ${breakPoint.small}px) {
      flex-direction: column;
    }

    > div {
      position: relative;
      ${cardStyles}
      flex: 1;
      background-position: center;
      background-size: cover;

      &:not(:last-of-type) {
        margin-right: 2rem;
      }

      &:after {
        content: '';
        display: block;
        padding-top: 69.86%;
      }
    }
  `,
  VastuuGroup: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${layoutStyles}
    margin: 6rem auto;

    h2 {
      margin: 0.5rem 0 0 0;
      font-weight: 600;
      color: ${Color.black};

      @media (max-width: 750px) {
        font-size: 1.2rem;
      }
    }

    img {
      margin-top: 1.7rem;
      opacity: 0.5;
    }
  `,
  Footer: styled.div`
    padding: 8rem 0;
    background-color: ${Color.secondary};
    // https://unsplash.com/photos/ESZRBtkQ_f8
    background-image: linear-gradient(to right, ${transparentize(0.4, Color.secondary)}, ${transparentize(0.8, Color.secondary)}),
      url(${imageFromCDN('/constle_footer_background.png')});
    background-position: center 38%;
    background-size: cover;
    box-shadow: none;

    @media (max-width: 750px) {
      padding: 6rem 0 8rem 0;
      text-align: center;

      background-image: linear-gradient(to bottom, ${transparentize(0.4, Color.secondary)}, ${transparentize(0.6, Color.secondary)}),
        url(${imageFromCDN('/constle_footer_background.png')});
    }

    > div {
      ${layoutStyles}
    }

    img {
      max-width: 100%;
    }

    h1 {
      margin: 0;
      font-size: 3rem;
      color: ${Color.white};

      @media (max-width: ${breakPoint.small}px) {
        font-size: 2rem;
      }
    }

    h2 {
      margin: 0.5rem 0 0 0;
      font-size: 1.8rem;
      font-weight: 500;
      color: ${lighten(0.2, Color.primary)};

      @media (max-width: 750px) {
        font-size: 1.2rem;
      }
    }

    p {
      margin: 2rem 0 0 0;
      max-width: 50%;
      font-size: 1.2rem;
      color: ${Color.white};

      @media (max-width: ${breakPoint.medium}px) {
        max-width: 70%;
      }

      @media (max-width: ${breakPoint.small}px) {
        padding: 0 2rem;
        max-width: 100%;
        font-size: 1rem;
        box-sizing: border-box;
      }
    }
  `,
};

const flippingPurposes = [
  <FormattedMessage id="frontPage.hero.purposeInformation" defaultMessage="tietopankki" />,
  <FormattedMessage id="frontPage.hero.purposeRegister" defaultMessage="toimittajarekisteri" />,
  <FormattedMessage id="frontPage.hero.purposeManagement" defaultMessage="hallintatyökalu" />,
];

const Home: FC = () => {
  const { isLoggedIn, hasPremium } = useUser();
  const { companies, isLoading, doLoadState } = useScopedState(recentlyUpdatedState, ['companies', 'isLoading']);

  useEffect(() => {
    doLoadState().catch(console.error);
  }, []);

  const companyHistory = isLoggedIn ? loadCompanyHistory() : [];

  const [purpose, setPurpose] = useState(flippingPurposes[0]);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      index = (index + 1) % flippingPurposes.length;
      setPurpose(flippingPurposes[index]);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <SEO />

      <Page>
        <Styled.Hero>
          {!isLoggedIn && (
            <div>
              <h1>
                <FormattedMessage
                  id="frontPage.hero.slogan"
                  defaultMessage="Yksi tehokas tarjouslaskennan ja työmaajohtamisen yhdistävä prosessi"
                />
                {/* <br />
                <Flip>{purpose}</Flip> */}
              </h1>
              <h2>
                <FormattedMessage id="frontPage.hero.tagline" defaultMessage="Säästä aikaa, rahaa ja vaivaa" />
              </h2>
              <small>
                <FormattedMessage
                  id="frontPage.hero.vastuuGroupText"
                  defaultMessage="Palvelu on kehitetty yhteistyössä VastuuGroupin kanssa."
                />
              </small>

              <br />

              <Button href="/login" endIcon={<ArrowRightIcon />}>
                Päivitä tietosi
              </Button>
            </div>
          )}

          {isLoggedIn && !hasPremium && (
            <div>
              <h1>
                <DiamondIcon /> <FormattedMessage id="premium.hero.title" defaultMessage="Constle Pro -jäsenyys" />
              </h1>
              <h2>
                <FormattedMessage id="premium.hero.subtitle" defaultMessage="Jäsenenä nautit kaikista Constlen tuomista eduista" />
              </h2>

              <p>
                <FormattedMessage
                  id="premium.hero.text"
                  defaultMessage="Constle on rakennusalan ammattilaisille kehitetty helppokäyttöinen työkalu, jonka avulla löydät tarvitsemasi yhteistyökumppanit hetkessä."
                />
              </p>

              <Button href="/premium" endIcon={<ArrowRightIcon />}>
                <FormattedMessage id="frontPage.premiumCallToAction" defaultMessage="Tilaa Pro" />
              </Button>
            </div>
          )}
        </Styled.Hero>

        <Styled.Scaffold>
          <Styled.Search>
            <Header icon={<BriefcaseSearchIcon />}>
              <FormattedMessage id="frontPage.hero.searchLabel" defaultMessage="Hae yrityksiä" />
            </Header>
            <Omnibar />
          </Styled.Search>

          {/* Last visited */}
          {!!companyHistory.length && (
            <CompanyCarousel
              title={<FormattedMessage id="frontPage.recentlyUpdated.title" defaultMessage="Viimeksi katsotut" />}
              icon={<HistoryIcon />}
              companies={companyHistory}
            />
          )}

          {!isLoggedIn && (
            <Styled.Callout>
              <div>
                <div />
                <div />
              </div>

              <div>
                <h1>
                  <ShieldCheckIcon />
                  <FormattedMessage id="frontPage.findOutMore.title" defaultMessage="Paranna kilpailuetuasi kokeilemalla itse" />
                </h1>

                <p>
                  <b>
                    <FormattedMessage id="frontPage.findOutMore.consumer.title" defaultMessage="Työsuoritteiden tilaajat" />
                  </b>

                  <FormattedMessage
                    id="frontPage.findOutMore.consumer.text"
                    defaultMessage="Constlen avulla voit olla varma siitä, että toimittajarekisterissäsi olevien kumppaneiden tiedot ovat aina ajan tasalla. Constlessa voit myös luoda uusia henkilökohtaisia listauksia tai jakaa nämä yrityksessäsi parilla klikkauksella. Enää ei siis tarvitse metsästää kiireen keskellä tarvitsemiasi toimijoita, vaan löydät ne hetkessä Constlen avulla."
                  />
                </p>

                <p>
                  <b>
                    <FormattedMessage id="frontPage.findOutMore.provider.title" defaultMessage="Työsuoritteiden tarjoajat" />
                  </b>

                  <FormattedMessage
                    id="frontPage.findOutMore.provider.text"
                    defaultMessage="Täydentämällä ja päivittämällä omaa yritystäsi koskevia tietoja tavoitat tehokkaasti kaikki tarvitsemasi toimijat. Voit myös lisätä kiinnostavuuttasi täydentämällä profiilisi mahdollisimman kattavasti. Profiilisi muokkaus on helppoa ja onnistuu parissa minuutissa."
                  />
                </p>

                <Button href="login" endIcon={<ArrowRightIcon />}>
                  <FormattedMessage id="frontPage.findOutMore.registerButton" defaultMessage="Kokeile jo tänään" />
                </Button>
              </div>
            </Styled.Callout>
          )}
        </Styled.Scaffold>

        <SearchHero />

        <ListsShowcase />

        <CompanyShowcase />

        <Styled.Scaffold>
          {/*
          <Styled.About>
            <div>
              <div />
              <div />
            </div>
            <div>
              <h1>
                <CommentQuestionIcon /> Mikä on Constle?
              </h1>

              <p>
                Constle on rakennusalan ammattilaisille kehitetty helppokäyttöinen työkalu, jonka avulla löydät tarvitsemasi
                yhteistyökumppanit hetkessä. Ratkaisumme avulla ylläpidät myös toimittajarekisterisi ajan tasalla ilman suurta manuaalista
                päivitystyötä.
                <br />
                <br />
                Tietokantamme kattaa <b>+100 000</b> rakennusalalla toimivan yrityksen tiedot sellaisella laajuudella jota ei ole ennen
                nähty. Ratkaisumme tulee muuttamaan alan yritysten tavat uusien kumppaneiden tunnistamisessa ja toimittajarekisterien
                ylläpidossa.
                <br />
                <br />
                Constle on kehitetty yhteistyössä Vastuu Groupin kanssa.
              </p>
            </div>
          </Styled.About>
          */}

          <Styled.Scaffold>
            {/* Last updated */}
            <CompanyCarousel
              title={<FormattedMessage id="frontPage.recentlyUpdated.title" defaultMessage="Viimeksi päivitetyt" />}
              icon={<NewspaperVariantMultipleIcon />}
              companies={companies}
              loading={isLoading}
            />
          </Styled.Scaffold>

          <Styled.VgBanners>
            {Math.floor(new Date().getMinutes() / 30) % 0 == 0 ? (
              <a target="_blank" href="https://www.vastuugroup.fi/fi-fi/palvelut/luotettava-kumppani">
                <img src={imageFromCDN('/banneri1_2021.jpg')} />
              </a>
            ) : (
              <a target="_blank" href="https://www.vastuugroup.fi/fi-fi/palvelut/valttikortti">
                <img src={imageFromCDN('/banneri2_2021.jpg')} />
              </a>
            )}
          </Styled.VgBanners>
        </Styled.Scaffold>

        <Styled.VastuuGroup>
          <h2>
            <FormattedMessage id="frontPage.vastuugroup.textBefore" defaultMessage="Yhteistyössä" />
          </h2>

          <img src={imageFromCDN('/vastuu_group_logo_musta.png')} width={240} height={35.866} alt="vastuu group logo" />
        </Styled.VastuuGroup>

        {/*
        <Styled.Footer>
          <div>
            <h1>{slogan}</h1>
            <h2>Everyday. Anywhere. Anytime.</h2>

            <p>Ota alasi parhaat työkeikat haltuun hakemalla ilmaiseksi Constlen avoimeen alihankkijaverkostoon.</p>
          </div>
        </Styled.Footer>
        */}
      </Page>
    </>
  );
};

/** Bake advanced search options into the static site to allow showing them instantly */
export const getStaticProps: GetStaticProps = async (ctx) => {
  const advancedSearchOptions = await fetchAdvancedSearchOptions();

  return {
    props: advancedSearchOptions,
  };
};

export default Home;
