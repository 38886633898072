import { FC, useMemo } from 'react';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from 'mdi-react/FolderIcon';
import FolderOpenIcon from 'mdi-react/FolderOpenIcon';
import { WWWListGroup } from '../../common/documents/WWWList';
import { ListsListRow } from '../lists/ListsListRow';
import { lists } from './exampleListData';

const handleSelect = () => {};

export const ListsShowcaseGroup: FC<WWWListGroup & { open?: boolean }> = ({ listGroupID, label, open = false }) => {
  const items = useMemo(
    () => (lists.filter((list) => list.listGroupID === listGroupID) || []).sort((a, b) => a.label.localeCompare(b.label)),
    [listGroupID]
  );

  return (
    <>
      <ListItem tabIndex={-1} button>
        <ListItemIcon style={{ opacity: items.length > 0 ? 1 : 0.6 }}>{open ? <FolderOpenIcon /> : <FolderIcon />}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((list) => (
            <ListsListRow key={list.listID} {...list} tabIndex={-1} inner selected={false} onSelect={handleSelect} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
