import React, { FC, MouseEvent, isValidElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import Link from 'next/link';
import type { UrlObject } from 'url';
import MuiMenu, { MenuProps as MuiMenuProps } from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import CheckboxMarkedIcon from 'mdi-react/CheckboxMarkedIcon';
import CheckboxBlankOutlineIcon from 'mdi-react/CheckboxBlankOutlineIcon';
import FolderIcon from 'mdi-react/FolderIcon';
import FolderOpenIcon from 'mdi-react/FolderOpenIcon';
import { Color } from './Theme';

const QUICK_ACTION_MARGIN_RIGHT = 4;

const MenuWithQuickActions = styled(MuiMenu)`
  .MuiListSubheader-root {
    margin: 0.5rem 0;
    line-height: initial;
  }

  .MuiListItemIcon-root {
    margin-right: 1rem;
    min-width: auto;
  }

  .MuiMenuItem-root {
    padding-right: 1.5rem;
    max-width: 300px;
  }

  .MuiMenuItem-root .MuiTypography-root {
    white-space: initial;
  }

  .MuiDivider-root {
    margin: 8px 0;
  }

  .MuiMenuItem-root.menu-quick-action {
    margin: 0.5rem 0 0 1rem;
    padding: 0.5rem;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: ${Color.black};

    box-sizing: content-box;

    &.active {
      background-color: ${Color.primary};
      color: ${Color.white};

      &.Mui-focused,
      &:focus,
      &:hover {
        background-color: ${darken(0.1, Color.primary)};
      }
    }

    .mdi-icon {
      width: 20px;
      height: 20px;
    }
  }
`;

const Dot = styled.div`
  position: absolute;
  margin: -16px 0 0 16px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${Color.primary};
  pointer-events: none;
`;

export interface MenuQuickActionProps {
  id: string;
  icon: JSX.Element;
  title: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: (id: string, event: MouseEvent<HTMLElement>) => void;
}

const MenuQuickAction: FC<MenuQuickActionProps & { index: number }> = ({ id, icon, title, active, disabled, index, onClick }) => {
  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => onClick(id, event), [id, onClick]);

  return (
    <MuiMenuItem
      aria-label={title}
      style={{ position: 'absolute', top: 8, left: index * (36 + QUICK_ACTION_MARGIN_RIGHT) }}
      className={`menu-quick-action ${active ? 'active' : ''}`}
      disabled={disabled}
      onClick={handleClick}
    >
      {icon}
    </MuiMenuItem>
  );
};

export interface MenuItemProps {
  id: string;
  icon?: JSX.Element;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;

  /** Add extra content to the list item */
  content?: JSX.Element;

  /** Add subitems */
  items?: MenuItemProps[];

  elementProps?: { [key: string]: any };

  overrideOpen?: boolean;

  header?: boolean;
  selected?: boolean;
  disabled?: boolean;
  checkbox?: boolean;
  dot?: boolean;
  inner?: boolean;
  href?: string | UrlObject;
  onClick?: (id: string, event: MouseEvent<HTMLElement>) => void;
}

export const MenuItem: FC<MenuItemProps & { disableRecursion?: boolean }> = ({ disableRecursion = false, ...props }) => {
  const {
    id,
    icon,
    title,
    subtitle,
    content = null,
    elementProps = {},
    disabled,
    selected = false,
    checkbox = false,
    dot = false,
    inner = false,
    items = false,
    href,
    onClick,
  } = props;

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => onClick && onClick(id, event), [id, onClick]);

  if (items && !disableRecursion) return <MenuItemGroup {...props} />;

  const menuItem = (
    <MuiMenuItem
      selected={selected}
      onClick={handleClick}
      disabled={disabled}
      style={inner ? { paddingLeft: '2rem' } : {}}
      {...elementProps}
    >
      {dot && <Dot />}
      {(checkbox || !!icon) && (
        <ListItemIcon>
          {checkbox && !icon ? (
            selected ? (
              <CheckboxMarkedIcon color={Color.primary} size={18} />
            ) : (
              <CheckboxBlankOutlineIcon size={18} />
            )
          ) : (
            icon
          )}
        </ListItemIcon>
      )}
      <ListItemText primary={title} secondary={subtitle} style={{ wordBreak: 'break-word' }} />
      {content}
    </MuiMenuItem>
  );

  if (href) {
    // Special case for mailto links, skip Next.js Link component
    if (typeof href === 'string' && href.startsWith('mailto:')) {
      return <a href={href}>{menuItem}</a>;
    }

    return (
      <Link key={id} href={href}>
        <a>{menuItem}</a>
      </Link>
    );
  } else {
    return menuItem;
  }
};

export const MenuItemGroup: FC<MenuItemProps> = (props) => {
  const [innerOpen, setOpen] = useState(false);

  const open = props.overrideOpen || innerOpen;

  if (!props.items || !props.items.length) return null;

  const handleClick = useCallback(
    (id: string, event: MouseEvent<HTMLElement>) => {
      setOpen((value) => !value);
      if (props.onClick) props.onClick(id, event);
    },
    [props.onClick, setOpen]
  );

  const icon = open ? <FolderOpenIcon size={18} /> : <FolderIcon size={18} />;

  return (
    <>
      <MenuItem icon={icon} {...props} onClick={handleClick} disableRecursion />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.items.map((item, i) => (
            <MenuItem key={i} {...item} inner />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export interface MenuProps extends MuiMenuProps {
  checkboxes?: boolean;
  quickActions?: MenuQuickActionProps[];
  items: (MenuItemProps | JSX.Element | null)[];
  width?: number | string | null;
}

export const Menu: FC<MenuProps> = ({ checkboxes, quickActions = [], items = [], width = null, children, ...props }) => {
  const renderMenuItem = useCallback(
    (item: MenuItemProps | JSX.Element | null) => {
      if (item === null) return null;
      if (isValidElement(item)) return item;

      const { id, title, elementProps = {}, header, checkbox, href, items } = item as MenuItemProps;

      const isCheckbox = checkbox || checkboxes;

      if (header) {
        if (!title) return <Divider key={id} />;
        return (
          <ListSubheader key={id} {...elementProps}>
            {title}
          </ListSubheader>
        );
      }

      const menuItem = <MenuItem key={id} {...(item as MenuItemProps)} checkbox={isCheckbox} />;

      if (href) {
        return (
          <Link key={id} href={href}>
            <a>{menuItem}</a>
          </Link>
        );
      } else {
        return menuItem;
      }
    },
    [checkboxes]
  );

  // Because quick actions have to be implemented with absolute positioning
  // to preserve the tab item order for the menu, we will need to calculate
  // a width for the menu quick actions manually.
  // padding left + (padding right - quick action right margin) + quick action count * (quick action width + quick action right margin)
  const minMenuWidthFromQuickActions = 16 + (16 - QUICK_ACTION_MARGIN_RIGHT) + quickActions.length * (36 + QUICK_ACTION_MARGIN_RIGHT);

  return (
    <MenuWithQuickActions
      {...props}
      MenuListProps={{
        style: {
          paddingTop: quickActions.length ? 36 + 8 + 16 : 8,
          minWidth: minMenuWidthFromQuickActions,
          maxHeight: 500,
          width: width ?? 'auto',
        },
      }}
    >
      {(quickActions || []).map((item, index) => (
        <MenuQuickAction key={item.id} {...item} index={index} />
      ))}
      {items.map(renderMenuItem)}
    </MenuWithQuickActions>
  );
};
