import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import { useOnScreen } from '../../hooks/useOnScreen';
import { Color, borderRadius } from '../Theme';
import { listGroups } from './exampleListData';
import { ListsShowcaseGroup } from './ListsShowcaseGroup';

const Container = styled.aside`
  width: min(calc(100vw - 16px), 400px);
  height: 325px;
  background-color: ${Color.white};
  border-radius: ${borderRadius}px;
  overflow: hidden;

  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;

  > nav {
    width: min(calc(100vw - 16px), 400px);

    .logo {
      background-image: url('/demoCompanyConstle.png') !important;

      svg {
        display: none;
      }
    }
  }
`;

export const ListsShowcaseSidebar: FC = () => {
  const isSmall = useBreakPoint('small');
  const containerRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(containerRef, { rootMargin: `${isSmall ? '-30%' : '-350px'}` });
  const [openListGroupID, setOpenListGroupID] = useState([]);

  useEffect(() => {
    if (!isVisible) return;

    const animate = () =>
      setOpenListGroupID((value) => {
        if (value.length === 0) return [1025];
        if (value.length === 1) return [1026, 1027];
        return [];
      });

    const timeout = setTimeout(animate, 500);
    const interval = setInterval(animate, 3000);
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [isVisible]);

  return (
    <Container ref={containerRef}>
      <List tabIndex={-1} disablePadding component="nav">
        {listGroups.map((listGroup, index) => (
          <ListsShowcaseGroup key={listGroup.listGroupID} {...listGroup} open={openListGroupID.includes(listGroup.listGroupID)} />
        ))}
      </List>
    </Container>
  );
};
