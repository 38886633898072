import { RefObject, useEffect, useState } from 'react';

export const useOnScreen = (ref: RefObject<HTMLElement>, { rootMargin = '0px', onlyOnce = false } = {}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentElement = ref?.current;
    if (!currentElement) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible((state) => (onlyOnce ? state || entry.isIntersecting : entry.isIntersecting));
      },
      {
        rootMargin,
      }
    );

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      observer.unobserve(currentElement);
    };
  }, [rootMargin]);

  return isVisible;
};
